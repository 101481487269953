import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader"

import "../components/wig-bundle.css"
import config from "../../config.json"
import { serializeNode } from "../utils/wysiwyg"

function formatWysiwig(context) {
  let contentToReturn = '<div class="wysiwig">'
  JSON.parse(context).map((item, i) => {
    contentToReturn += `<p key="wig-item-${i}">
      ${serializeNode(item)}
    </p>`

    return true
  })
  contentToReturn += "</div>"
  return contentToReturn
}

require("es6-promise").polyfill()

export default function HomepageDefaultPage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  console.log("integrators data: ")
  console.log(integratorData)

  console.log("content meta data: ")
  console.log(contentMetaData)

  console.log("content data data: ")
  console.log(contentDataData)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.seoTitle}</title>
        <meta name="description" content={contentMetaData.metaDescription} />
      </Helmet>

      <div className="page__header-container page__header-container--blog-landing">
        <div
          className="banner"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundImage: `
              url("${contentDataData[0].children[0] &&
                contentDataData[0].children[0].value
                ? contentDataData[0].children[0].value
                : "https://cdn2.accropress.com/content-uploads%2F29cdbd1f-7836-436a-8b16-1b5a14d31ba3%2Fe5b6307e-e6a4-4cb1-834e-fbc333c43631%2F9ok5DsFGSHCCquEywcot_banner_jpg"
              }")`,
          }}
        >
          <div className="text-block">
            <h1>{contentDataData[0].children[1].value}</h1>
          </div>
        </div>
        <div className="container">
          <div
            className="row justify-content-center page__header page__header--landing blog-landing__header page__header--blog-landing"
            id="listing-top"
          >
            <div className="col-12 col-lg-12">
              <WigLoader
                contentDataData={contentDataData}
                contentMetaData={contentMetaData}
                contentDataArray="1"
                contentDataChild="0"
                AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                integratorID={config.integratorID}
                integratorAccessToken={config.integratorAccessToken}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
