import escapeHtml from "escape-html"
import { Text } from "slate"

import React from "react"

require("es6-promise").polyfill()

export const serializeNode = node => {
  if (Text.isText(node)) {
    if (node["heading-one"] || node["h1"]) {
      return `<h1>${node.text}</h1>`
    }

    if (node["heading-two"] || node["h2"]) {
      return `<h2>${node.text}</h2>`
    }

    if (node["heading-three"] || node["h3"]) {
      return `<h3>${node.text}</h3>`
    }

    if (node["heading-four"] || node["h4"]) {
      return `<h4>${node.text}</h4>`
    }

    if (node["heading-five"] || node["h5"]) {
      return `<h5>${node.text}</h5>`
    }

    if (node["heading-six"] || node["h6"]) {
      return `<h6>${node.text}</h6>`
    }

    if (node["bold"]) {
      return `<strong>${node.text}</strong>`
    }

    if (node["italic"]) {
      return `<em>${node.text}</em>`
    }

    if (node["underline"]) {
      return `<u>${node.text}</u>`
    }

    if (node["strikethrough"]) {
      return `<del>${node.text}</del>`
    }

    if (node["code"]) {
      return `<code>${node.text}</code>`
    }

    return escapeHtml(node.text)
  }

  if (node.children) {
    const children = node.children.map(n => serializeNode(n)).join("")

    switch (node.type) {
      case "container":
        return `<div>${children}</div>`
      case "table":
        return (
          <table>
            <tbody>{children}</tbody>
          </table>
        )
      case "table-row":
        return <tr>{children}</tr>
      case "table-cell":
        return <td>{children}</td>
      case "heading-one":
        return `<h1>${children}</h1>`
      case "heading-two":
        return `<h2>${children}</h2>`
      case "heading-three":
        return `<h3>${children}</h3>`
      case "heading-four":
        return `<h4>${children}</h4>`
      case "heading-five":
        return `<h5>${children}</h5>`
      case "heading-six":
        return `<h6>${children}</h6>`
      case "h1":
        return `<h1>${children}</h1>`
      case "h2":
        return `<h2>${children}</h2>`
      case "h3":
        return `<h3>${children}</h3>`
      case "h4":
        return `<h4>${children}</h4>`
      case "h5":
        return `<h5>${children}</h5>`
      case "h6":
        return `<h6>${children}</h6>`
      case "quote":
        return `<blockquote><p>${children}</p></blockquote>`
      case "block-quote":
        return `<blockquote><p>${children}</p></blockquote>`
      case "paragraph":
        return `<p>${children}</p>`
      case "p":
        return `<p>${children}</p>`
      case "link":
        return `<a href="${escapeHtml(node.url)}">${children}</a>`
      case "a":
        return `<a href="${escapeHtml(node.url)}">${children}</a>`
      case "align-center":
        return `<p align="center">${children}</p>`
      case "align-left":
        return `<p align="left">${children}</p>`
      case "align-right":
        return `<p align="right">${children}</p>`
      case "bulleted-list":
        return `<ul>${children}</ul>`
      case "numbered-list":
        return `<ol>${children}</ol>`
      case "list-item":
        return `<li>${children}</li>`
      case "file":
        return `<a className="ap-file-component" target="_blank" href=${node.url}>
              <div className="ap-file-component-extension">
                ${node.extension}
              </div>
              <div className="ap-file-component-filename">${node.filename}</div>
            </a>`
      case "iframe":
        return `<div
            style="position: relative"
          >
            <iframe
              class="responsive-iframe"
              src=${node.url}
              frameBorder="0"
              style="
                position: relative;
                width: 100%;
                height: auto;
                minHeight: 350px;
              "
            />
          </div>
          ${children}`
      case "image":
        return `<div style="width: 100%">
            <img
              alt="${node.alt}"
              src="${node.url}"
              style="width: 100%;"
            />
            ${children}
          </div>`
      case "img":
        return `<div style="width: 100%">
            <img
              alt="${node.alt}"
              src="${node.url}"
              style="width: 100%;"
            />
            ${children}
          </div>`
      case "video":
        return `<div style="width: 100%">
          <video width="100%" controls playsinline>
            <source src="${node.url}" type="video/mp4">
            Your browser does not support HTML video.
          </video>
          ${children}
        </div>`
      default:
        return `${children}`
    }
  }
}

export const isJsonString = function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
